.video-body-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px;
}

.video-body-item-1 {
    flex-basis: 100%;
    margin: 10px;
}

.video-body-item-2 {
    flex-basis:47%;
    margin: 10px;
}

.video-body-item {
    flex-basis: 31.81%;
    margin: 10px;
}

.video-body-container {
    position: relative;
    background-color: black;
}

.video-body-overlay {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.video-body-overlay-opacity{
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: rgba(0,0,0,0.70);
}

.video-body-params-container {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
}

.video-body-params {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-style: normal;
    text-align: center;
    color: white;
    padding-right: 30px;
}

.video-body-params-amount {
    font-size: 48px;
    line-height: 40px;
    height: 40px;
    font-family: Rubik;
    font-size: 2rem;
    font-weight: 300;
}

.video-body-params-unit {
    font-size: 18px;
    text-transform: uppercase;
    font-family: Rubik;
    font-size: 1rem;
    font-weight: 400;
}

.video-body-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 24.67%, rgba(0, 0, 0, 0.65) 100%);
    height: 130px;
}

.video-body-title {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding-bottom: 30px;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: Rubik;
}

@media (max-width: 1439px) {
    .video-body-item {
        flex-basis: 47%;
    }
}

@media (max-width: 1024px) {
    .video-body-item {
        flex-basis: 100%;
    }
}
