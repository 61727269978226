.error-container {
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-item {
    font-size: 20px;
    justify-content: center;
    font-family: Rubik;
    margin-top: 60px;
    text-align: center;
}

.error-logo {
    height: 42px;
}

@media (min-width: 320px) {
    .error-logo {
        max-height: 20px;
    }  
}
  
@media (min-width: 480px) {
    .error-logo {
        max-height: 32px;
    }
}
  
@media (min-width: 768px) {
    .error-logo {
        max-height: 32px;
    }  
}
  
@media (min-width: 1024px) {
    .error-logo {
        max-height: 42px;
    }  
}
  
@media (min-width: 1194px) {
    .error-logo {
        max-height: 42px;
    }
}
  
@media (min-width: 1439px) {
    .error-logo {
        max-height: 67px;
    }
}
  
@media (min-width: 1920px) {
    .error-logo {
        max-height: 67px;
    }
}