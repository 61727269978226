.player-wrapper {
    position: relative;
    padding-top: 56.25%
        /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.App {
    height: 100%;
    width: 100%;
}

@media (min-width: 320px) {
    .App {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        padding-bottom: 12px;

    }
}

@media (min-width: 480px) {
    .App {
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 36px;
        padding-bottom: 36px;
    }
}

@media (min-width: 768px) {
    .App {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

@media (min-width: 1024px) {
    .App {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@media (min-width: 1194px) {
    .App {
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@media (min-width: 1439px) {
    .App {
        padding-left: 72px;
        padding-right: 72px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@media (min-width: 1920px) {
    .App {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 60px;
    }
}
