.video-body-layout-opacity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh;
  opacity: 0.6;
  z-index: 1 !important; /* Specify a stack order in case you're using a different order for other elements */
}

.video-body-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh;
  z-index: 1 !important; /* Specify a stack order in case you're using a different order for other elements */
}

.video-body-flex-container {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
}

.video-body-timer-value {
  font-family: Rubik;
  font-size: 90px;
}

.video-body-timer-title {
  font-size: 40px;
  font-family: Rubik;
}

.video-body-timer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-body-description {
  font-size: 25px;
  font-family: Rubik;
  margin-left: 30px;
}

.video-body-bottom-message {
  position: absolute;
  height: 100vh;
  padding: 30px;
}

.video-body-flex-bottom-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  color: white;
  font-family: Rubik;
}

.video-body-bottom-message-title {
  font-size: 30px;
  margin-bottom: 15px;
}

.video-body-bottom-message-subtitle {
  font-size: 40px;
}

.video-body-flex-item {
  flex-basis: 50%;
  flex-grow: 1;
}

