.timer-container-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
}

.timer-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
