@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.video-header-container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  color: white;
  font-family: Rubik;
  font-style: normal;
}

.video-header-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-header-flex-item {
    flex-basis: 33%;
    flex-grow: 1;
}

.video-header-data-mobile {
  display: flex;
  flex-direction: row;
}

.video-header-data {
  display: flex;
  flex-direction: column;
}

.video-header-round {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
}

.video-header-page {
  display: flex;
  flex-direction: column;
}

.video-header-logo {
  display: flex;
  align-items: center;
}

.video-header-logo-mobile {
  display: none;
}

.video-header-image {
  max-width: 302px !important;
}

.video-header-timer {
  position: absolute;
  z-index: 1000;
  right:5%;
}

@media (max-width: 767px) {
  .video-header-page {
    margin-top: 10px;
  }
  
  .video-header-data-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .video-header-logo {
    display: flex;
    align-items: center;
    display: none;
  }

  .video-header-logo-mobile {
    display: block;
  }
}

@media (min-width: 320px) {
  .video-header-image {
    max-height: 20px !important;
  }

  .video-header-container {
    height: 160px;
  }

  .video-header-round-title {
    font-size: 1rem !important;
    font-weight: 300;
  }
  
  .video-header-page-title {
    font-size: 1rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 0.625rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 0.625rem !important;
    font-weight: 300;
  }
}

@media (min-width: 480px) {
  .video-header-container {
    height: 160px;
  }

  .video-header-round-title {
    font-size: 1.15rem !important;
    font-weight: 300;
  }
  
  .video-header-page-title {
    font-size: 1.15rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 0.625rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 0.625rem !important;
    font-weight: 300;
  }
}

@media (min-width: 768px) {
  .video-header-image {
    max-height: 32px !important;
  }

  .video-header-container {
    height: 110px;
  }

  .video-header-round-title {
    font-size: 1rem !important;
    font-weight: 300;
  }
  
  .video-header-page-title {
    font-size: 1.25rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 0.75rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 0.75rem !important;
    font-weight: 300;
  }
}

@media (min-width: 1024px) {
  .video-header-image {
    max-height: 42px !important;
  }

  .video-header-container {
    height: 144px;
  }

  .video-header-round-title {
    font-size: 1.5rem !important;
    font-weight: 300;
  }

  .video-header-page-title {
    font-size: 1.5rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
}

@media (min-width: 1194px) {
  .video-header-image {
    max-height: 42px !important;
  }

  .video-header-container {
    height: 144px;
  }

  .video-header-round-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }

  .video-header-page-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }
  .video-header-round-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
}

@media (min-width: 1439px) {
  .video-header-image {
    max-height: 67px !important;
  }

  .video-header-container {
    height: 144px;
  }

  .video-header-round-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }

  .video-header-page-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
}

@media (min-width: 1920px) {
  .video-header-image {
    max-height: 67px !important;
  }

  .video-header-container {
    height: 144px;
  }

  .video-header-round-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }

  .video-header-page-title {
    font-size: 1.75rem !important;
    font-weight: 300;
  }

  .video-header-round-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
  
  .video-header-page-value {
    text-transform: uppercase;
    font-size: 1.125rem !important;
    font-weight: 300;
  }
}